<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
          class="wizard wizard-2"
          id="kt_wizard_v2"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-6">
                <h4>
                  {{ studentParentsVisitLogId ? "Edit meet" : "Add meet" }}
                </h4>
                <router-link :to="{ name: 'dashboard' }"> Dashboard \ </router-link>
                <router-link :to="{ name: 'student-parents-visit-logs' }">
                  Parents meet
                </router-link>
              </div>
            </div>

            <div class="row">
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  :error="$v.studentParentsVisitLog.title.$error"
                  v-model="studentParentsVisitLog.title"
                >
                  <template v-slot:label>
                    Title
                    <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger" v-if="$v.studentParentsVisitLog.title.$error"
                  >This information is required</span
                >
              </v-col>

              <v-col cols="12" md="12">
                <v-select
                  :items="types"
                  item-text="title"
                  item-value="value"
                  v-model="studentParentsVisitLog.user_type"
                  outlined
                  dense
                  :error="$v.studentParentsVisitLog.user_type.$error"
                  placeholder="User type"
                >
                  <template v-slot:label>
                    User type
                    <span class="text-danger">*</span>
                  </template>
                </v-select>
                <span
                  class="text-danger"
                  v-if="$v.studentParentsVisitLog.user_type.$error"
                  >This information is required</span
                >
              </v-col>

              <v-col cols="12" md="2" v-if="studentParentsVisitLog.user_type == 'class'">
                <v-select
                  :items="academicYears"
                  class="form-control"
                  :menu-props="{ button: true, offsetY: true }"
                  v-model="studentParentsVisitLog.academic_year_id"
                  label="Academic year"
                  item-value="id"
                  item-text="year"
                  outlined
                  dense>
                </v-select>
              </v-col>

              <v-col cols="12" md="2" v-if="studentParentsVisitLog.user_type == 'class'">
                <v-select
                  :items="levels"
                  :menu-props="{ button: true, offsetY: true }"
                  class="form-control"
                  v-model="studentParentsVisitLog.level_id"
                  label="Level"
                  item-value="id"
                  item-text="title"
                  outlined
                  @change="getPrograms"
                  dense>
                </v-select>
              </v-col>

              <v-col cols="12" md="2" v-if="studentParentsVisitLog.user_type == 'class'">
                <v-select
                  outlined dense
                  v-model="studentParentsVisitLog.program_id"
                  :items="programs"
                  label="Programs"
                  :loading="programLoading"
                  item-value="id"
                  :menu-props="{ button: true, offsetY: true }"
                  item-text="title"
                  @change="getGrades"
                >
                </v-select>
              </v-col>

              <v-col cols="12" md="3" v-if="studentParentsVisitLog.user_type == 'class'">
                <v-select
                  outlined
                  dense
                  v-model="studentParentsVisitLog.grade_id"
                  :items="gradesLevels"
                  label="Semester/year"
                  :menu-props="{ button: true, offsetY: true }"
                  item-value="id"
                  item-text="title"
                  :loading="semesterLoading"
                  @change="getAcademicClasses"
                ></v-select>
              </v-col>

              <v-col cols="12" md="3" v-if="studentParentsVisitLog.user_type == 'class'">
                <v-select
                  outlined
                  dense
                  class="form-control"
                  v-model="studentParentsVisitLog.class_id"
                  :items="academic_classes"
                  :menu-props="{ button: true, offsetY: true }"
                  :loading="classLoading"
                  @change="checkStudentsInClass"
                  item-value="id"
                  item-text="title"
                  :error="$v.studentParentsVisitLog.class_id.$error"
                >
                  <template v-slot:label>
                    Class <span class="text-danger">*</span>
                  </template>
                </v-select>

                <div class="text-danger mt-2" v-if="$v.studentParentsVisitLog.class_id.$error"
                >This information is required</div>

                <div class="text-danger mt-2" v-if="studentNotAvailable">
                This class does not have student
                </div>
              </v-col>

              <!-- <v-col cols="12" md="3" v-if="studentParentsVisitLog.user_type == 'class'">
                <v-autocomplete
                  @change="selectClass"
                  v-model="selectedClass"
                  outlined
                  dense
                  :items="classes"
                  hide-selected
                  item-text="display_text"
                  item-value="id"
                  :search-input.sync="classSearch"
                  @input="studentParentsVisitLog.class_id = $event !== null ? $event : ''"
                  :loading="isClassLoading"
                  clearable
                  placeholder="Search by class unique no, title or shift"
                  :error="$v.studentParentsVisitLog.class_id.$error"
                >
                  <template v-slot:label>
                    Class<span class="text-danger">*</span>
                  </template>
                  <template v-slot:no-data>
                    <div class="no-data-auto-complete text-center">No data available</div>
                  </template>
                </v-autocomplete>
                <span class="text-danger" v-if="$v.studentParentsVisitLog.class_id.$error"
                  >This information is required</span
                >
              </v-col> -->

              <v-col
                cols="12"
                md="12"
                v-if="studentParentsVisitLog.user_type == 'student'"
              >
                <v-autocomplete
                  @change="selectUser"
                  v-model="selectedUser"
                  outlined
                  dense
                  :items="users"
                  hide-selected
                  item-text="display_text"
                  item-value="id"
                  :search-input.sync="userSearch"
                  @input="studentParentsVisitLog.user_id = $event !== null ? $event : ''"
                  :loading="isUserLoading"
                  clearable
                  placeholder="Search by student's name, email or CRN"
                  :error="$v.studentParentsVisitLog.user_id.$error"
                >
                  <template v-slot:label>
                    Student<span class="text-danger">*</span>
                  </template>
                  <template v-slot:no-data>
                    <div class="no-data-auto-complete text-center">No data available</div>
                  </template>
                </v-autocomplete>
                <span class="text-danger" v-if="$v.studentParentsVisitLog.user_id.$error"
                  >This information is required</span
                >
              </v-col>
            </div>

            <div class="row">
              <v-col cols="12" md="12">
                <v-select
                  :items="meeting_types"
                  item-text="title"
                  item-value="value"
                  v-model="studentParentsVisitLog.meeting_type"
                  outlined
                  dense
                  :error="$v.studentParentsVisitLog.meeting_type.$error"
                  placeholder="Meeting type"
                >
                  <template v-slot:label>
                    Meeting type
                    <span class="text-danger">*</span>
                  </template>
                </v-select>
                <span
                  class="text-danger"
                  v-if="$v.studentParentsVisitLog.meeting_type.$error"
                  >This information is required</span
                >
              </v-col>

              <v-col cols="12" v-if="studentParentsVisitLog.meeting_type == 'online'">
                <v-text-field
                  outlined
                  dense
                  placeholder="Meeting link"
                  :error="$v.studentParentsVisitLog.online_meeting_link.$error"
                  v-model="studentParentsVisitLog.online_meeting_link"
                >
                  <template v-slot:label>
                    Meeting link
                    <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span
                  class="text-danger"
                  v-if="$v.studentParentsVisitLog.online_meeting_link.$error"
                  >This information is required</span
                >
              </v-col>

              <v-col
                cols="12"
                v-if="studentParentsVisitLog.meeting_type == 'outside_premise'"
              >
                <v-text-field
                  outlined
                  dense
                  :error="$v.studentParentsVisitLog.outside_premise_location.$error"
                  placeholder="Premise location"
                  v-model="studentParentsVisitLog.outside_premise_location"
                >
                  <template v-slot:label>
                    Premise location
                    <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span
                  class="text-danger"
                  v-if="$v.studentParentsVisitLog.outside_premise_location.$error"
                  >This information is required</span
                >
              </v-col>

              <v-col cols="12" md="12">
                <v-datetime-picker
                  outlined
                  dense
                  ref="dateTime"
                  label="Datetime requested to visit"
                  :text-field-props="textFieldProps"
                  :datePickerProps="datePickerProps"
                  v-model="studentParentsVisitLog.datetime_requested_to_visit"
                >
                </v-datetime-picker>
                <span
                  class="text-danger"
                  v-if="$v.studentParentsVisitLog.datetime_requested_to_visit.$error"
                  >This information is required</span
                >
              </v-col>

              <v-col cols="12" md="12">
                    <label>
                        Request for visit reason  <span class="text-danger">*</span>
                    </label>
                    <ckeditor
                        v-model="studentParentsVisitLog.request_for_visit_reason"
                        :config="editorConfig"
                    ></ckeditor>
                    <span class="text-danger" v-if="$v.studentParentsVisitLog.request_for_visit_reason.$error">This information is required</span>
                    <div class="text-danger" v-if="errors.request_for_visit_reason">{{ errors.request_for_visit_reason[0] }}</div>
                </v-col>
            </div>

            <div class="row">
              <div class="col-12 text-right">
                <v-btn class="text-gray btn btn-standard" depressed @click="cancel"
                  >Cancel
                </v-btn>
                <v-btn
                  class="text-white ml-2 btn btn-primary"
                  depressed
                  @click="createOrUpdate"
                  :loading="isBusy"
                  :disabled="studentParentsVisitLog.user_type == 'class' && studentNotAvailable"
                  v-if="checkIsAccessible('parent-meet', 'edit') || checkIsAccessible('parent-meet', 'edit')"
                  >Save
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import StudentParentsVisitLogsService from "@/core/services/student-parents-visit-logs/StudentParentsVisitLogsService";
import UserService from "@/core/services/user/UserService";
import UserParentService from "@/core/services/user/UserParentService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import ProgramService from "@/core/services/level/program/ProgramService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";

const user = new UserService();
const userParent = new UserParentService();
const parentMeet = new StudentParentsVisitLogsService();
const academicClass = new AcademicClassService();
const academicYearService = new AcademicYearService();
const levelService = new LevelService()
const programService = new ProgramService();
const gradeLevelService = new GradeLevelService();


export default {
  validations: {
    studentParentsVisitLog: {
        title: { required },
        user_type: { required },
        user_id: {
            required: requiredIf(function(){
                if(this.studentParentsVisitLog.user_type == 'student')
                    return true;
                return false;
            })
        },
        class_id: {
            required: requiredIf(function(){
                if(this.studentParentsVisitLog.user_type == 'class')
                    return true;
                return false;
            })
        },
        meeting_type: { required },
        online_meeting_link: { required: requiredIf(function(){
                if(this.studentParentsVisitLog.meeting_type == 'online')
                    return true;
                return false;
            })
        },
        outside_premise_location: { required: requiredIf(function(){
                if(this.studentParentsVisitLog.meeting_type == 'outside_premise')
                    return true;
                return false;
            })
        },
        datetime_requested_to_visit: { required },
        request_for_visit_reason: {required}
    },
  },
  data() {
    return {
      dialog: false,
      isBusy: false,
      menu: false,
      menu1: false,
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
          ],
        ],
      },
      studentParentsVisitLog: {
        user_type: "",
        user_parent_id: null,
        user_id: null,
        datetime_requested_to_visit: null,
        academic_year_id: '',
        level_id: '',
        program_id: '',
        grade_id: '',
        class_id: ''

      },
      errors: [],
      classes: [],
      classSearch: "",
      userSearch: "",
      attendedByUserSearch: "",
      isClassLoading: false,
      isUserLoading: false,
      isAttendedByUserLoading: false,
      users: [],
      attendedByUsers: [],
      textFieldProps: {
        outlined: true,
        dense: true,
      },
      datePickerProps: {
        min: ''
      },
      timePickerProps: {
        min: ''
      },
      types: [
        {
          title: "Class",
          value: "class",
        },
        {
          title: "Student",
          value: "student",
        },
      ],
      meeting_types: [
        {
          title: "Online",
          value: "online",
        },
        {
          title: "In premise",
          value: "in_premise",
        },
        {
          title: "Outside premise",
          value: "outside_premise",
        },
      ],
      academicYears: [],
      gradesLevels: [],
      programs: [],
      levels: [],
      academic_classes: [],
      programLoading: false,
      semesterLoading: false,
      classLoading: false,
      studentNotAvailable: false,
      currentDateTime: new Date()
    };
  },
  mounted() {
    this.getAcademicYears()
    this.getLevels()
    this.setMinDate()
    this.setMinTime()
    let today = new Date();

    if (this.studentParentsVisitLogId) {
      // this.getInventoryAssignmentList();
    }
  },
  computed: {
    studentParentsVisitLogId() {
      return this.$route.params.studentParentsVisitLogId;
    },
  },
  methods: {
    cancel() {
      this.$tabs.close();
    },
    createOrUpdate() {
      this.$v.studentParentsVisitLog.$touch();
      if (this.$v.studentParentsVisitLog.$error) {
        setTimeout(() => {
          this.$v.studentParentsVisitLog.$reset();
        }, 3000);
      } else {
        if (this.studentParentsVisitLogId) {
          this.updateStudentParentsVisitLog();
        } else {
          this.createStudentParentsVisitLog();
        }
      }
    },
    updateStudentParentsVisitLog() {
      this.isBusy = true;
      parentMeet
        .update(this.inventoryAssignedList.id, this.inventoryAssignedList)
        .then((response) => {
          this.isBusy = false;
          this.$snotify.success("Information updated");
          // this.$tabs.close();
          this.resetForm();
        })
        .catch((error) => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
        });
    },
    createStudentParentsVisitLog() {
      this.isBusy = true;

      this.formatRequestedDateTime();
      this.studentParentsVisitLog.status = 'requested';

      parentMeet
        .store(this.studentParentsVisitLog)
        .then((response) => {
          this.isBusy = false;
          this.resetForm();
          this.$snotify.success("Information added");
          this.$tabs.close();
        })
        .catch((error) => {
          this.isBusy = false;
          this.errors = error.errors;
          this.$snotify.error("Something Went Wrong");
        });
    },
    resetForm() {
      this.$v.studentParentsVisitLog.$reset();
      this.studentParentsVisitLog = {
        user_parent_id: null,
        user_id: null,
        datetime_requested_to_visit: null,
        request_for_visit_reason: "",
        datetime_of_visit: null,
        parent_comments: "",
        attendee_comments: "",
        attended_by: null,
        user_type: "",
        status: "",
        academic_year_id: '',
        level_id: '',
        program_id: '',
        grade_id: '',
        class_id: ''
      };
    },
    formatRequestedDateTime() {
      if (this.studentParentsVisitLog.datetime_requested_to_visit) {
        var fullDate = new Date(this.studentParentsVisitLog.datetime_requested_to_visit);
        const year = fullDate.getFullYear();
        const month = parseInt(fullDate.getMonth()) + parseInt(1);
        const day = fullDate.getDate();
        const hour = fullDate.getHours();
        const minute = fullDate.getMinutes();

        const entryDateTime =
          year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + "00";
        this.studentParentsVisitLog.datetime_requested_to_visit = null;
        this.studentParentsVisitLog.datetime_requested_to_visit = entryDateTime;
      }
      return;
    },

    selectClass() {
      if (this.selectedClass && this.selectedClass != null) {
        this.studentParentsVisitLog.class_id = this.selectedClass;
      } else {
        this.studentParentsVisitLog.class_id = "";
      }
    },
    selectedClass(val) {
      if (!val) {
        this.studentParentsVisitLog.class_id = "";
      }
    },

    selectUser() {
      if (this.selectedUser && this.selectedUser != null) {
        this.studentParentsVisitLog.user_id = this.selectedUser;
      } else {
        this.studentParentsVisitLog.user_id = "";
      }
    },
    selectedUser(val) {
      if (!val) {
        this.studentParentsVisitLog.user_id = "";
      }
    },

    checkStudentsInClass(){
      academicClass
      .checkIfStudentExists(this.studentParentsVisitLog.class_id)
      .then((res) => {
        if(res.data.students.length > 0){
          this.studentNotAvailable = false;
        }else{
          this.studentNotAvailable = true;
        }
      })
      .catch((err) => {

      });
    },

    searchUser(val) {
      this.isUserLoading = true;

      let data = {
        val: val,
        type: "student",
      };

      user
        .filter(data)
        .then((response) => {
          response.data.users.map((user) => {
            let data = user;
            data.display_text =
              user.full_name + " | " + user.personal_id + " | " + user.email;
            this.users.push(data);
          });
          this.selectedUser = response.data.users[0];
        })
        .catch((err) => {})
        .finally(() => (this.isUserLoading = false));
    },

    searchClass(val) {
      this.isClassLoading = true;

      let data = {
        val: val,
      };

      academicClass
        .search(data)
        .then((response) => {
          response.data.classes.map((item) => {
            let data = item;
            data.display_text =
              item.unique_no + " | " + item.title + " | " + item.shift;
            this.classes.push(data);
          });
          this.selectedClass = response.data.classes[0];
        })
        .catch((err) => {})
        .finally(() => (this.isClassLoading = false));
    },

    getAcademicYears() {
      academicYearService.all().then(response => {
        this.academicYears = response.data;
      });
    },

    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data;
      });
    },

    getPrograms() {
      this.programLoading = true;
      programService.getAllByLevelId(this.studentParentsVisitLog.level_id).then(response => {
        this.programs = response.data;
        this.programLoading = false;
        this.studentParentsVisitLog.grade_id = null;
        this.studentParentsVisitLog.class_id = null;
        this.studentNotAvailable = false;
      });
    },

    getGrades() {
      this.semesterLoading = true;
      gradeLevelService.getByProgram(this.studentParentsVisitLog.program_id).then(response => {
        this.gradesLevels = response.data;
        this.semesterLoading = false;
        this.studentParentsVisitLog.class_id = null;
        this.studentNotAvailable = false;
      });
    },

    getAcademicClasses() {
      this.classLoading = true;
      academicClass
      .getByProgramLevel(this.studentParentsVisitLog.academic_year_id, this.studentParentsVisitLog.program_id, this.studentParentsVisitLog.grade_id)
      .then(response => {
        this.academic_classes = response.data;
        this.classLoading = false;
        this.studentNotAvailable = false;
      });
    },

    setMinDate(){
      this.datePickerProps.min = new Date().toJSON().slice(0, 10);
    },

    setMinTime(){
      let today = new Date();
      this.timePickerProps.min = today.getHours() + ":" + today.getMinutes()
    }
  },
  watch: {
    classSearch(val) {
      this.isClassLoading = true;

      let data = {
        val: val,
      };

      academicClass
        .search(data)
        .then((response) => {
          response.data.classes.map((item) => {
            let data = item;
            data.display_text = item.unique_no + " | " + item.title + " | " + item.shift;
            this.classes.push(data);
          });
        })
        .catch((err) => {})
        .finally(() => (this.isClassLoading = false));
    },

    userSearch(val) {
      this.isUserLoading = true;

      let data = {
        val: val,
        type: "student",
      };

      user
        .filter(data)
        .then((response) => {
          response.data.users.map((user) => {
            let data = user;
            data.display_text =
              user.full_name + " | " + user.personal_id + " | " + user.email;
            this.users.push(data);
          });
        })
        .catch((err) => {})
        .finally(() => (this.isUserLoading = false));
    }
  },
};
</script>
